<template>
  <page-layout ref="layout">
    <template #breadcrumbs>
      <b-breadcrumb-item text="Management" />
      <b-breadcrumb-item text="Instruments" :to="{ name: 'management-instruments' }" />
      <b-breadcrumb-item :text="instrument.name" active />
    </template>

    <template #actions="{ state }">
      <can do="update" on="management-instrument">
        <b-button v-if="state.editing" v-b-tooltip="'Update'" variant="transparent" size="sm" @click="updateInstrument">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="management-instrument">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item @click="updateState">
          <font-awesome-icon :icon="['fas', instrument.state.enabled ? 'toggle-off' : 'toggle-on']"></font-awesome-icon>
          <span class="align-middle ml-50">{{ instrument.state.enabled ? 'Disable' : 'Enable' }}</span>
        </b-dropdown-item>
        <can do="delete" on="management-instrument">
          <b-dropdown-item @click="$refs.layout.confirmDelete(instrument, deleteInstrument, cascadeConfirmDeleteOptions)">
            <feather-icon icon="Trash2Icon"/>
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </can>
        <b-dropdown-divider/>
      </can>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <validation-observer ref="observer" tag="div">
        <form ref="form">
          <b-row>
            <b-col cols="6">
              <b-card>
                <b-row>
                  <b-col align-self="start" cols="auto">
                    <b-avatar v-if="hasCustomAvatar(instrument.avatar)" v-b-modal.avatar-modal variant="primary" size="8em" badge-variant="white" :disabled="!state.editing">
                      <b-img fluid fluid-grow :src="instrument.avatar.src" :alt="instrument.avatar.name"></b-img>
                      <template #badge>
                        <b-icon :icon="enabledIcon(instrument.state.enabled)" :variant="enabledIconVariant(instrument.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="6em" badge-variant="white" :disabled="!state.editing">
                      <font-awesome-icon :icon="icon" size="3x"></font-awesome-icon>
                      <template #badge>
                        <b-icon :icon="enabledIcon(instrument.state.enabled)" :variant="enabledIconVariant(instrument.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <avatar-modal v-if="state.editing"
                                  title="Instrument Avatar"
                                  :avatar="instrument.avatar"
                                  @update-avatar="updateAvatar"/>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col align-self="start">
                        <validation-provider v-slot="validationContext" vid="name" name="Instrument Name" rules="required">
                          <b-form-group label="Instrument Name" label-for="name-input" :invalid-feedback="validationContext.errors[0]">
                            <b-input v-model="instrument.name" placeholder="Instrument Name" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-input>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col align-self="start" class="col-xxl-auto col-12">
                        <validation-provider v-slot="validationContext" vid="order" name="Instrument Order" rules="required|double">
                          <b-form-group label="Instrument Order" label-for="order-input" :invalid-feedback="validationContext.errors[0]">
                            <b-input v-model="instrument.order" placeholder="0" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-input>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col>
              <b-card>
                <validation-provider v-slot="validationContext" vid="zone" name="Form" rules="required">
                  <b-form-group label="Form" label-for="form-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <v-select id="form-input"
                              v-model="instrument.form"
                              :disabled="!state.editing"
                              :loading="state.loading"
                              label="name"
                              :options="options.forms"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="Form"
                              class="form-control-plaintext w-100"/>
                  </b-form-group>
                </validation-provider>
              </b-card>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </template>

    <template #debug>
      <debug title="Instrument">{{ instrument }}</debug>
      <debug title="Forms">{{ options.forms }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import AvatarModal from '@/components/Avatar/AvatarModal.vue';
import slugify from 'slugify';
import vSelect from 'vue-select'
import avatar from '@/mixins/avatar.mixin'
import notify from '@/mixins/notify.mixin'
import status from '@/mixins/status.mixin'
import role from '@/mixins/role.mixin';
import print from '@/mixins/print.mixin';
import { API, graphqlOperation } from 'aws-amplify';
import { getInstrument, getInstrumentBySlug, listForms } from '@/graphql/queries/instrument';
import {deleteEnsembleInstruments, deleteInstrument, updateApplication, updateInstrument} from '@/graphql/mutations';
import {listApplicationsForUpdate, listEnsembleInstrumentsForDeletion} from '@/graphql/queries/instruments';
import { cascadeDeleteInstrument, cascadeConfirmDeleteOptions} from '@/graphql/cascade/instrument';

export default {
  components: {
    PageLayout,
    AvatarModal,
    vSelect
  },
  mixins: [ avatar, role, status, print, notify ],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      instrument: null,
      icon: 'fas fa-music',
      options: {
        forms: []
      },
      state: {
        loading: true,
        editing: false
      },
      cascadeConfirmDeleteOptions
    }
  },
  async mounted() {
    await this.getInstrument();
    await this.getForms();
  },
  methods: {

    async getInstrument() {
      if(this.id) {
        const response = await API.graphql(graphqlOperation(getInstrument, { id: this.id }));
        this.instrument = response.data.getInstrument
      }
      else {
        const response = await API.graphql(graphqlOperation(getInstrumentBySlug, { slug: this.slug }));
        // eslint-disable-next-line prefer-destructuring
        this.instrument = response.data.getInstrumentBySlug.items[0]
      }
      this.$refs.layout.state.loading = false
    },
    async patchInstrument(input) {
      try {
        const response = await API.graphql(graphqlOperation(updateInstrument, { input: input } ));
        this.zone = response.data.updateInstrument;
        this.notify({ title: 'Success', text: 'Instrument was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        this.notify({ title: 'Error', text: 'Instrument failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async updateInstrument() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.patchInstrument({
          id: this.instrument.id,
          name: this.instrument.name,
          order: this.instrument.order,
          slug: slugify(this.instrument.name, { lower: true }),
          formInstrumentsId: this.instrument.form ? this.instrument.form.id : null
        })
      }
      else {
        this.notify({ title: 'Warning', text: 'Instrument failed to update. Validation Failed.', icon: this.icon, variant: 'warning'});
      }
    },
    async deleteInstrument(instrument, swalCallback) {
      try {
        await this.cascadeDeleteInstrument(instrument.id, swalCallback)
        await this.notify({ title: 'Success', text: 'Instrument was successfully deleted', icon: this.icon, variant: 'success' });
        await this.$router.push({ name: 'management-instruments' })
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Instrument failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for swal
      }
    },
    cascadeDeleteInstrument,

    // eslint-disable-next-line no-shadow
    async updateAvatar(avatar) {
      this.festival.avatar = avatar;
      await this.patchInstrument({ id: this.instrument.id, avatar: this.instrument.avatar })
    },
    async updateState() {
      this.instrument.state.enabled = !this.instrument.state.enabled;
      await this.patchInstrument({ id: this.instrument.id, state: this.instrument.state })
    },


    async getForms() {
      const response = await API.graphql(graphqlOperation(listForms));
      this.options.forms = response.data.listForms.items.sort((a, b) => a.name.localeCompare(b.name));
    },

    async refresh() {
      this.$refs.layout.state.loading = false
      await this.getInstrument();
      await this.getForms();
      this.$refs.layout.state.loading = false
    },

    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
  }
}
</script>

<style lang="scss">
.form-control-plaintext {
  border: 0!important;
  border-radius: 0!important;
  -webkit-box-shadow: unset!important;
  box-shadow: unset!important;
  padding: 0!important;
  margin: 0!important;
  transition: unset!important;
  height: unset!important;
  /*font-size: inherit!important;
  font-weight: inherit!important;*/
}

.form-control-plaintext[readonly] {
  background-color: unset!important;
  opacity: 1;
}

.form-control-plaintext:focus-visible {
  outline: 0;
}
.form-control-plaintext:focus {
  color: inherit;
  background-color: inherit;
  border: 0;
  outline: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}
</style>
