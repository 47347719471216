/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Instrument may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Applications and Ensembles associated with this instrument will have their association removed.<br/>
            This may effect applications, scoring, selections, acceptance, as well as reports.
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Instrument was successfully deleted.' }
}

export async function cascadeDeleteInstrument(instrumentId, swalCallback) {
    try {
        /** Delete Ensemble-Instrument records associated to this Instrument **/
        const ensembleInstruments = await listEnsembleInstruments(instrumentId, swalCallback)
        await batchProcessWithRetry(ensembleInstruments, async (ensembleInstrument) => {
            await API.graphql(graphqlOperation(deleteEnsembleInstrumentsMutation, { input: { id: ensembleInstrument.id }}));
        }, { title: 'Deleting Ensemble Instruments', callback: swalCallback })

        /** Update Application records associated to this Instrument **/
        const applications = await listApplications(instrumentId, swalCallback)
        await batchProcessWithRetry(applications, async (application) => {
            await API.graphql(graphqlOperation(updateApplicationMutation, { input: { id: application.id, applicationInstrumentId: null }}));
        }, { title: 'Updating Applications', callback: swalCallback })

        /** Delete Instrument **/
        await deleteInstrument(instrumentId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteInstrument(instrumentId, swalCallback) {
    const title = 'Deleting Instrument'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteInstrumentMutation, {input: { id: instrumentId }}));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function listApplications(instrumentId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { applicationInstrumentId: { eq: instrumentId } } }
            const response = await API.graphql(graphqlOperation(listApplicationsQuery, input));
            items.push(...response.data.listApplications.items)
            nextToken = response.data.listApplications.nextToken;
            swalCallback(new SwalData('Loading Applications', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

async function listEnsembleInstruments(instrumentId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { instrumentID: { eq: instrumentId } } }
            const response = await API.graphql(graphqlOperation(listEnsembleInstrumentsQuery, input));
            items.push(...response.data.listEnsembleInstruments.items)
            nextToken = response.data.listEnsembleInstruments.nextToken;
            swalCallback(new SwalData('Loading Ensemble Instruments', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

/** Queries & Mutations **/
const listEnsembleInstrumentsQuery = /* GraphQL */ `
    query ListEnsembleInstruments(
        $filter: ModelEnsembleInstrumentsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listEnsembleInstruments(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                instrumentID
            }
            nextToken
        }
    }
`;

const deleteEnsembleInstrumentsMutation = /* GraphQL */ `
    mutation DeleteEnsembleInstruments(
        $input: DeleteEnsembleInstrumentsInput!
        $condition: ModelEnsembleInstrumentsConditionInput
    ) {
        deleteEnsembleInstruments(input: $input, condition: $condition) {
            id
        }
    }
`;

const listApplicationsQuery = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                applicationInstrumentId
            }
            nextToken
        }
    }
`;

const updateApplicationMutation = /* GraphQL */ `
    mutation UpdateApplication(
        $input: UpdateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        updateApplication(input: $input, condition: $condition) {
            id
            applicationInstrumentId
        }
    }
`;

const deleteInstrumentMutation = /* GraphQL */ `
    mutation DeleteInstrument(
        $input: DeleteInstrumentInput!
        $condition: ModelInstrumentConditionInput
    ) {
        deleteInstrument(input: $input, condition: $condition) {
            id
        }
    }
`;
