export const getInstrument = /* GraphQL */ `
    query GetInstrument($id: ID!) {
        getInstrument(id: $id) {
            id
            slug
            name
            order
            form {
                id
                name
            }
            state {
                enabled
            }
        }
    }
`;

export const getInstrumentBySlug = /* GraphQL */ `
    query GetInstrumentBySlug(
        $slug: String!
        $sortDirection: ModelSortDirection
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        getInstrumentBySlug(
            slug: $slug
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                slug
                name
                order
                form {
                    id
                    name
                }
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const listForms = /* GraphQL */ `
    query ListForms(
        $id: ID
        $filter: ModelFormFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listForms(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                state {
                    enabled
                }
            }
        }
    }
`;
