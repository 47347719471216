export const listInstruments = /* GraphQL */`
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name
                order
                form {
                    id
                    name
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                formInstrumentsId
            }
            nextToken
        }
    }
`;

export const listEnsembleInstrumentsForDeletion = /* GraphQL */ `
    query ListEnsembleInstruments(
        $filter: ModelEnsembleInstrumentsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listEnsembleInstruments(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
            }
            nextToken
        }
    }
`;

export const listApplicationsForUpdate = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                applicationInstrumentId
            }
            nextToken
        }
    }
`;

export const updateInstrument = /* GraphQL */ `
    mutation UpdateInstrument(
        $input: UpdateInstrumentInput!
        $condition: ModelInstrumentConditionInput
    ) {
        updateInstrument(input: $input, condition: $condition) {
            id
            slug
            name
            order
            form {
                id
                name
                state {
                    enabled
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            formInstrumentsId
        }
    }
`;
export const deleteInstrument = /* GraphQL */ `
    mutation DeleteInstrument(
        $input: DeleteInstrumentInput!
        $condition: ModelInstrumentConditionInput
    ) {
        deleteInstrument(input: $input, condition: $condition) {
            id
            slug
            name
            order
            form {
                id
                name
                state {
                    enabled
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            formInstrumentsId
        }
    }
`;
export const deleteEnsembleInstruments = /* GraphQL */ `
    mutation DeleteEnsembleInstruments(
        $input: DeleteEnsembleInstrumentsInput!
        $condition: ModelEnsembleInstrumentsConditionInput
    ) {
        deleteEnsembleInstruments(input: $input, condition: $condition) {
            id
            ensembleID
            instrumentID
            ensemble {
                id
                slug
                name
                parts
                instruments {
                    nextToken
                }
                hasParts
                hasAlternates
                state {
                    enabled
                }
                createdAt
                updatedAt
            }
            instrument {
                id
                slug
                name
                order
                form {
                    id
                    slug
                    name
                    type
                    createdAt
                    updatedAt
                }
                ensembles {
                    nextToken
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                formInstrumentsId
            }
            createdAt
            updatedAt
        }
    }
`;
export const updateApplication = /* GraphQL */ `
    mutation UpdateApplication(
        $input: UpdateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        updateApplication(input: $input, condition: $condition) {
            id
            festival {
                id
                slug
                name
                site
                zone {
                    id
                    name
                    counties
                    createdAt
                    updatedAt
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                festivalZoneId
            }
            form {
                id
                slug
                name
                type
                instruments {
                    nextToken
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
            }
            instrument {
                id
                slug
                name
                order
                form {
                    id
                    slug
                    name
                    type
                    createdAt
                    updatedAt
                }
                ensembles {
                    nextToken
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                formInstrumentsId
            }
            student {
                id
                name {
                    first
                    last
                }
                dob
                sex
                gender
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                phone {
                    type
                    number
                    ext
                }
                email {
                    type
                    address
                }
                grade
                school {
                    id
                    beds
                    slug
                    createdAt
                    updatedAt
                    districtSchoolsId
                    schoolZoneId
                }
                applications {
                    nextToken
                }
                bill {
                    id
                    memo
                    duplicates
                    createdAt
                    updatedAt
                    billStudentId
                }
                createdAt
                updatedAt
                schoolID
                studentBillId
            }
            teacher {
                id
                username
                name {
                    first
                    last
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                schools {
                    nextToken
                }
                ensembleIds
                instrumentIds
                avatar {
                    size
                    name
                    src
                }
                createdAt
                updatedAt
                districtUsersId
            }
            questions {
                lastYear {
                    attended
                    group
                    part
                    chair
                }
                moreThanOne {
                    response
                    instruments
                }
                organizations {
                    school
                    other
                }
                experience {
                    vocalJazzAccompaniment
                    jazzEnsemble
                    orchestral
                }
            }
            grading {
                tone
                rhythmicSense
                leadership
                maturity
                articulation
                intonation
                citizenship
                diction
                flexibility
                generalMusicianship
                attitude
                technique
                sightReading
                responsibility
            }
            recommendation
            comments
            ranking {
                score
                local
                state
            }
            selections {
                items {
                    id
                    part
                    accepted
                    createdAt
                    updatedAt
                    applicationSelectionsId
                    selectionEnsembleId
                }
                nextToken
            }
            selection {
                id
                part
                ranking {
                    state
                }
                ensemble {
                    id
                    slug
                    name
                    parts
                    hasParts
                    hasAlternates
                    createdAt
                    updatedAt
                }
                accepted
                application {
                    id
                    recommendation
                    comments
                    createdAt
                    updatedAt
                    studentApplicationsId
                    applicationFestivalId
                    applicationFormId
                    applicationInstrumentId
                    teacherID
                    applicationSelectionId
                }
                state {
                    order
                }
                createdAt
                updatedAt
                applicationSelectionsId
                selectionEnsembleId
            }
            createdAt
            updatedAt
            studentApplicationsId
            applicationFestivalId
            applicationFormId
            applicationInstrumentId
            teacherID
            applicationSelectionId
        }
    }
`;

export const onCreateInstrument = /* GraphQL */ `
    subscription OnCreateInstrument {
        onCreateInstrument {
            id
            slug
            name
            order
            form {
                id
                name
                state {
                    enabled
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            formInstrumentsId
        }
    }
`;
export const onUpdateInstrument = /* GraphQL */ `
    subscription OnUpdateInstrument {
        onUpdateInstrument {
            id
            slug
            name
            order
            form {
                id
                name
                state {
                    enabled
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            formInstrumentsId
        }
    }
`;
export const onDeleteInstrument = /* GraphQL */ `
    subscription OnDeleteInstrument {
        onDeleteInstrument {
            id
            slug
            name
            order
            form {
                id
                name
                state {
                    enabled
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            formInstrumentsId
        }
    }
`;
